<template>
  <NewsOfTheDayEditView disabled :news-of-the-day-id="newsOfTheDayId" />
</template>

<script>
import NewsOfTheDayEditView from '@/views/newsOfTheDay/NewsOfTheDayEditView'

export default {
  name: 'NewsOfTheDayView',
  components: {
    NewsOfTheDayEditView
  },
  props: {
    newsOfTheDayId: {
      type: Number,
      required: false
    }
  }
}
</script>
