<script>
import NewsOfTheDayNewView from '@/views/newsOfTheDay/NewsOfTheDayNewView.vue'
import ModuleView from '@/components/ModuleView.vue'

export default {
  name: 'NewsOfTheDayEditView',
  extends: NewsOfTheDayNewView,
  components: {
    appModuleView: ModuleView
  },
  props: {
    newsOfTheDayId: {
      type: Number,
      required: false
    }
  },
  methods: {
  },
  async mounted () {
    await this.getNewsOfTheDay()
  }
}
</script>
